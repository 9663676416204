import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
// import { Formik, Form } from 'formik';
// import { object, string } from 'yup';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
// import withStyles from '@material-ui/core/styles/withStyles';

// testing Material-UI inputs in production
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Input from '@material-ui/core/Input';

import CloseIcon from '@material-ui/icons/Close';

// import EnhancedField from 'siteComponents/EnhancedField';
import Layout from '../components/Layout';
import BusinessWoman from '../assets/images/confident-business-woman.jpg';

import pageStyles from './pages.module.css';
import contactStyles from './contact.module.css';

// import snackbarContentStyle from 'assets/jss/material-dashboard-pro-react/components/snackbarContentStyle';

// const initialValues = {
//   name: '',
//   email: '',
//   subject: '',
//   message: ''
// }

// const shape = [
//   {
//     name: 'name',
//     title: 'Name'
//   },
//   {
//     name: 'email',
//     title: 'Email'
//   },
//   {
//     name: 'subject',
//     title: 'Subject'
//   },
//   {
//     name: 'message',
//     title: 'Message',
//     multiline: true,
//     rows: 4
//   }
// ]

// const schema = object().shape({
//   name: string().required('Please enter your name.'),
//   email: string().email('Please enter a valid email address.').required('Please enter your email address.'),
//   subject: string().required('Please enter a subject for your message.'),
//   message: string().required('Please fill in a message.')
// });

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      snackbarMessage: '',
      snackbarOpen: false
    };

    this.openSnackbar = this.openSnackbar.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openSnackbar(message) {
    this.setState({
      snackbarMessage: message,
      snackbarOpen: true
    });
  }

  closeSnackbar() {
    this.setState({
      snackbarOpen: false
    });
  }

  handleSubmit(values, { resetForm }) {
    console.log(values);

    this.setState({
      submitting: true
    }, () => {

      const errorMessage = 'Something went wrong. Please try your request again.';
      let message;
  
      fetch(process.env.GATSBY_API_BASE + '/contactForm', {
        method: 'post',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(r => r.json())
        .then(({ results }) => {
          let message;
          
          console.log(results);
          if(results.total_accepted_recipients > 0) {
            message = 'Message sent successfully';
            resetForm();
          } else {
            message = errorMessage;
          }
  
          this.openSnackbar(message);
          this.setState({
            submitting: false
          });
        })
        .catch(e => {
          console.log(e);
          message = errorMessage;
          this.openSnackbar(message);
          this.setState({
            submitting: false
          });
        });
    });
  }

  render() {
    // const { classes } = this.props;

    return (
      <Layout>
        <Helmet>
          <title>Contact Us - Replaix</title>
          <meta name="robots" content="noindex,follow"/>
          <link rel="canonical" href="https://replaix.com/contact"/>
        </Helmet>
        <Grid container className={pageStyles.container}>
          <Grid item xs={10} style={{ margin: '25px auto'}}>
            <Typography variant="h3">Contact Us</Typography>
          </Grid>
          <Grid item xs={10} lg={4} style={{ margin: '25px auto'}}>
            <div>
              {/* <Typography variant="body2">Get in touch.</Typography>
              <Typography variant="body2">Please fill out the quick form and we will be in touch shortly.</Typography> */}
              <Typography variant="body2" className={contactStyles.fontAdjust}>To get in touch please email us at <a href="mailto:support@replaix.com?subject=Support Inquiry From Contact Page">support@replaix.com</a>.</Typography>
              <Typography variant="body2" className={contactStyles.fontAdjust}>If you're feeling fancayyy, join our <a href={process.env.GATSBY_DISCORD_INVITE_LINK}>Discord channel</a>.</Typography>
            </div>
            {/* <form onSubmit={e => e.preventDefault()}>
              <FormControl>
                <InputLabel htmlFor="bunk">This is our form label</InputLabel>
                <Input name="bunk" id="bunk"/>
              </FormControl>
            </form> */}
            {/* <Formik
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              validationSchema={schema}
              render={props => (
                <Form>
                  {shape.map(props => (
                    <EnhancedField
                      key={props.name}
                      {...props}
                      styles={{
                        // display: 'block',
                        marginBottom: '10px'
                      }}
                      fullWidth
                    />
                  ))}
                  {this.state.submitting ? (
                    <CircularProgress/>
                  ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send
                  </Button>
                  )}
                </Form>
              )}
            /> */}
          </Grid>
          <Hidden mdDown>
            <Grid item lg={6}>
              <img src={BusinessWoman} width="500" alt="confident business woman"/>
            </Grid>
          </Hidden>
          {/* <Grid item xs={10} lg={10} style={{ margin: '25px auto'}}>
            <div>
              <Typography variant="body2">Email us at <a href="mailto:support@replaix.com?subject=Support Inquiry From Contact Page">support@replaix.com</a> for any questions</Typography>
            </div>
          </Grid> */}
        </Grid>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          onClose={this.closeSnackbar}
          message={this.state.snackbarMessage}
          action={[(
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              // className={classes.close}
              className={contactStyles.closeIcon}
              onClick={this.closeSnackbar}
            >
              <CloseIcon />
            </IconButton>
          )]}
        />
      </Layout>
    );
  }
}

// export default withStyles(snackbarContentStyle)(Contact);
export default Contact;